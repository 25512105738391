var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"okText":"确定","cancelText":"取消","title":"添加菜单"},on:{"ok":_vm.comfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('a-form',{ref:"collectionForm",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"菜单类型(关联商品或者关联分类)"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'type',
						{
						  initialValue:_vm.typename ,
						  rules: [{ required: true, message: `请填写菜单类型`}],
						}
					]),expression:"[\n\t\t\t\t\t\t'type',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue:typename ,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写菜单类型`}],\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],on:{"change":_vm.gettype}},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 分类 ")]),_c('a-radio',{attrs:{"value":"2"}},[_vm._v(" 服务 ")])],1)],1),(_vm.type == 1)?[(_vm.record.classify)?[_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'type_id',
								{ 
									initialValue: _vm.record.classify[0].children[0].id,
									rules: [{ required: true, message: '请填写分类'}] 
								},
							  ]),expression:"[\n\t\t\t\t\t\t\t\t'type_id',\n\t\t\t\t\t\t\t\t{ \n\t\t\t\t\t\t\t\t\tinitialValue: record.classify[0].children[0].id,\n\t\t\t\t\t\t\t\t\trules: [{ required: true, message: '请填写分类'}] \n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t  ]"}],on:{"change":_vm.getselect}},_vm._l((_vm.record.classify),function(item,index){return _c('a-select-opt-group',{attrs:{"label":item.name}},_vm._l((item.children),function(items,indexs){return _c('a-select-option',{key:items.id},[_vm._v(" "+_vm._s(items.name)+" ")])}),1)}),1)],1)]:_vm._e()]:[_c('a-form-item',{attrs:{"label":"服务"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'type_id',
								{
								  initialValue:_vm.cascader ,
								  rules: [{ required: true, message: `请填写`}],
								},
							  ]),expression:"[\n\t\t\t\t\t\t\t\t'type_id',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t  initialValue:cascader ,\n\t\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写`}],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t  ]"}],attrs:{"options":_vm.record.shop,"expand-trigger":"hover"},on:{"change":_vm.getcascader}})],1)],_c('a-form-item',{attrs:{"label":"菜单名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{
						  initialValue: _vm.record.data.name,
						  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.name,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写名称`, whitespace: true }],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}]})],1),(_vm.type == 2)?[(_vm.record.data.icon)?[_c('a-form-item',{attrs:{"label":"点击更换icon(68X68)"}},[_c('img',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'icon',
								{
								  initialValue: _vm.record.data.icon,
								  rules: [{ required: true, message: `请填写`, whitespace: true }],
								},
							  ]),expression:"[\n\t\t\t\t\t\t\t\t'icon',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t  initialValue: record.data.icon,\n\t\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写`, whitespace: true }],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t  ]"}],staticStyle:{"width":"22%","height":"60%","cursor":"pointer"},attrs:{"src":_vm.record.data.icon,"alt":""},on:{"click":function($event){return _vm.openresources(1)}}})])]:[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("上传icon(68X68) ")]),_c('div',{staticClass:"upload f-ajc",staticStyle:{"width":"25%","height":"60%","border":"1px dashed #CCCCCC","flex-direction":"column","padding":"30px","cursor":"pointer"},on:{"click":function($event){return _vm.openresources(1)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传图片 ")])],1)]]:[(_vm.record.data.icon)?[_c('a-form-item',{attrs:{"label":"点击更换icon(68X68)"}},[_c('img',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'icon',
								{
								  initialValue: _vm.record.data.icon,
								  rules: [{ required: true, message: `请填写`, whitespace: true }],
								},
							  ]),expression:"[\n\t\t\t\t\t\t\t\t'icon',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t  initialValue: record.data.icon,\n\t\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写`, whitespace: true }],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t  ]"}],staticStyle:{"width":"22%","height":"60%","cursor":"pointer"},attrs:{"src":_vm.record.data.icon,"alt":""},on:{"click":function($event){return _vm.openresources(1)}}})])]:[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("上传icon(68X68) ")]),_c('div',{staticClass:"upload f-ajc",staticStyle:{"width":"25%","height":"60%","border":"1px dashed #CCCCCC","flex-direction":"column","padding":"30px","cursor":"pointer"},on:{"click":function($event){return _vm.openresources(1)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传图片 ")])],1)],(_vm.record.data.banner)?[_c('a-form-item',{attrs:{"label":"点击更换banner(690X190)"}},[_c('img',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'banner',
								{
								  initialValue: _vm.record.data.banner,
								  rules: [{ required: true, message: `请填写`, whitespace: true }],
								},
							  ]),expression:"[\n\t\t\t\t\t\t\t\t'banner',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t  initialValue: record.data.banner,\n\t\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写`, whitespace: true }],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t  ]"}],staticStyle:{"width":"22%","height":"60%","cursor":"pointer"},attrs:{"src":_vm.record.data.banner,"alt":""},on:{"click":function($event){return _vm.openresources(2)}}})])]:[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("点击上传banner(690X190) ")]),_c('div',{staticClass:"upload f-ajc",staticStyle:{"width":"25%","height":"60%","border":"1px dashed #CCCCCC","flex-direction":"column","padding":"30px","cursor":"pointer"},on:{"click":function($event){return _vm.openresources(2)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传图片 ")])],1)]]],2),_c('resources',{ref:"resources",on:{"getimgpath":_vm.getimgpath}})]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }