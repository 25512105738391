<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="添加菜单" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">

					<!-- 选中分类或者服务 -->
					<a-form-item label="菜单类型(关联商品或者关联分类)">
						<a-radio-group @change="gettype" v-decorator="[
							'type',
							{
							  initialValue:typename ,
							  rules: [{ required: true, message: `请填写菜单类型`}],
							}
						]">
							<a-radio value="1">
								分类
							</a-radio>
							<a-radio value="2">
								服务
							</a-radio>
						</a-radio-group>
					</a-form-item>

					<template v-if="type == 1">
						<!-- 分类选择 -->
						<template v-if="record.classify">
							<a-form-item label="分类">
								<a-select @change="getselect" v-decorator="[
									'type_id',
									{ 
										initialValue: record.classify[0].children[0].id,
										rules: [{ required: true, message: '请填写分类'}] 
									},
								  ]">
									<a-select-opt-group v-for="(item,index) in record.classify" :label="item.name">
										  <a-select-option v-for="(items,indexs) in item.children"  :key="items.id">
											{{items.name}}
										  </a-select-option>
									</a-select-opt-group >
								</a-select>
							</a-form-item>
						</template>
						
					</template>
					<template v-else>
						<!-- 选择服务，级联选择器 -->
						<a-form-item label="服务">
							<a-cascader :options="record.shop" expand-trigger="hover" @change="getcascader" v-decorator="[
									'type_id',
									{
									  initialValue:cascader ,
									  rules: [{ required: true, message: `请填写`}],
									},
								  ]" />
						</a-form-item>
					</template>





					<!-- 名称 -->
					<a-form-item label="菜单名称">
						<a-input v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					<template v-if="type == 2">
						<!-- 图片icon -->
						<template v-if="record.data.icon">
							<a-form-item label="点击更换icon(68X68)">
								<img v-decorator="[
									'icon',
									{
									  initialValue: record.data.icon,
									  rules: [{ required: true, message: `请填写`, whitespace: true }],
									},
								  ]" style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.icon" alt="" @click="openresources(1)">
							</a-form-item>
						</template>
						<template v-else>
							<div style="margin-bottom: 15px;">
								<span style="color: red;">*</span>上传icon(68X68)
							</div>
							<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(1)">
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传图片
								</div>
							</div>
						</template>
					</template>
					<template v-else>
						<!-- 图片icon -->
						<template v-if="record.data.icon">
							<a-form-item label="点击更换icon(68X68)">
								<img v-decorator="[
									'icon',
									{
									  initialValue: record.data.icon,
									  rules: [{ required: true, message: `请填写`, whitespace: true }],
									},
								  ]" style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.icon" alt="" @click="openresources(1)">
							</a-form-item>
						</template>
						<template v-else>
							<div style="margin-bottom: 15px;">
								<span style="color: red;">*</span>上传icon(68X68)
							</div>
							<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(1)">
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传图片
								</div>
							</div>
						</template>
						<!-- banner -->
						<template v-if="record.data.banner">
							<a-form-item label="点击更换banner(690X190)">
								<img v-decorator="[
									'banner',
									{
									  initialValue: record.data.banner,
									  rules: [{ required: true, message: `请填写`, whitespace: true }],
									},
								  ]" style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.banner" alt="" @click="openresources(2)">
							</a-form-item>
						</template>
						
						<template v-else>
							<div style="margin-bottom: 15px;">
								<span style="color: red;">*</span>点击上传banner(690X190)
							</div>
							<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(2)">
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传图片
								</div>
							</div>
						</template>
						
					</template>
					
				</a-form>
				<!-- 图片管理器 -->
				<resources ref="resources"  @getimgpath="getimgpath"></resources>
			</template>


		</a-modal>
	</div>
</template>

<script>
	import resources from "../resoureces.vue"
	export default {
		components: {
			resources
		},
		props: {
			record: Object
		},
		computed: {
			// 级联选中默认
			cascader() {
				if (this.record.shop) {
					let arr = []
					arr.push(this.record.shop[0].value)
					arr.push(this.record.shop[0].children[0].value)
					arr.push(this.record.shop[0].children[0].children[0].value)
					return arr
				}
			},
			// 菜单类型
			typename() {
				if (this.record.data) {
					let type = '2'
					
					this.type = type
					return type
				}
			},
			
		},
		data() {
			return {
				show: false,
				type: '2',
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				witchbtn:1

			}
		},

		created() {

		},
		methods: {
			// 获取类型
			gettype(e) {
				// console.log(e, "类型");
				this.type = e.target.value.toString() 
				console.log(this.type, "类型");
			},
			// 获取商品
			getcascader(e) {
				console.log(e, "获取商品");
			},
			// 获取分类
			getselect(e) {
				console.log(e, "获取选中分类id");
			},
			// 打开图片管理器
			openresources(btn) {
				this.witchbtn  = btn
				this.$refs.resources.visibles = true
				// this.isresources = true
			},

			// 获取更改图片路径
			getimgpath(val) {
				// 收集数据集
				let payload = {
					val:val,
					witchbtn:this.witchbtn
				}
				this.$emit("changeimg", payload)
			},
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					let isarr = values.type_id instanceof Array
					if(isarr){
						values.type_id = values.type_id[values.type_id.length-1]
					}else{
						values.type_id = values.type_id
					}
					
					let payload = ""
					if(this.type == '1'){
						payload = {
							type:values.type,
							name:values.name,
							type_id:values.type_id,
							banner:values.banner,

							icon:values.icon
						}
					}else{
						payload = {
							type:values.type,
							name:values.name,

							icon:values.icon,
							type_id:values.type_id,
							banner:'',
						}
					}
					// 执行提交方法
					this.$emit("modifynav", payload)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
